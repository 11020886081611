body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mapboxgl-canvas {
  left: 0;
}
.ui[class*="top fixed"].menu {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: #ededed;
}
.ui.inverted.segment, .ui.primary.inverted.segment {
  background: #1d1d1b;
  color: rgba(255,255,255,.9);
}

.ui.section.divider {
  margin-top: 2rem;
  margin-bottom: 0rem;
}
.ui.vertical.menu {
    width: 100%;
}
.ui.selection.dropdown {
    min-width: auto;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}

.leaflet-container {
    z-index: 1;
    height: 500px;
    /* width: 800px; */
}

.ui.disabled.dropdown, .ui.dropdown .menu>.disabled.item {
    cursor: default;
    pointer-events: none;
    opacity: .45;
    color: red;
    opacity: 1;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
    pointer-events: none;
    opacity: 0.9;
}
.ui.selection.dropdown {
    min-width: auto;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}

.leaflet-container {
    z-index: 1;
    height: 500px;
    /* width: 800px; */
}

.ui.selection.dropdown {
    min-width: auto;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}

.ui.selection.dropdown {
    min-width: auto;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}

.ui.selection.dropdown {
    min-width: auto;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}

.ui.selection.dropdown {
    min-width: auto;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}

.ui.selection.dropdown {
    min-width: auto;
    width: 250px;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}

.ui.selection.dropdown {
    min-width: auto;
    width: 250px;
}
.ui.selection.dropdown {
    min-width: auto;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}
.ui.selection.dropdown {
    min-width: auto;
}
.ui.selection.dropdown {
    min-width: auto;
}
.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}

.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}

