.ui.disabled.fluid.left.icon.input {
    opacity: 1;
}

.leaflet-container {
    z-index: 1;
    height: 500px;
    /* width: 800px; */
}

.ui.disabled.dropdown, .ui.dropdown .menu>.disabled.item {
    cursor: default;
    pointer-events: none;
    opacity: .45;
    color: red;
    opacity: 1;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
    pointer-events: none;
    opacity: 0.9;
}